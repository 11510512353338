<template lang="pug">
  .container-fluid.p-5
    notification(
      :notification-id="notificationId"
      :host-id="hostId"
      :product-id="productId"
      :date="date"
    )

</template>

<script>
import NotificationDetails from "@/views/notifications/NotificationDetails.vue";
import Notification from "@/views/notifications/partials/Notification.vue";

export default {
  name: "NotificationDetailsModal",
  components: { Notification, NotificationDetails },
  props: {
    notificationId: {
      type: String,
      required: false,
    },
    hostId: {
      type: String,
      required: false,
    },
    productId: {
      type: String,
      required: false,
    },
    date: {
      type: Date,
      required: false,
    },
  },
};
</script>

<style scoped></style>
