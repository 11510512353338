import gql from "graphql-tag";

export const Users = {
    Queries: {
        ProductManagerUser: gql`
        query productManagerUser($userId: ID) {
            productManagerUser(userId: $userId) {
                Id
                Email
                FirstName
                LastName
                CreatedAt
                UpdatedAt
                IsDashboardUser
                IsProductManagerUser
                Guid
            }
        }`,
        ProductManagerUsers: gql`
        query productManagerUsers($offset: Int, $limit: Int, $filter: String) {
            productManagerUsers(offset: $offset, limit: $limit, filter: $filter) {
                Id
                Email
                FirstName
                LastName
                CreatedAt
                UpdatedAt
                IsDashboardUser
                IsProductManagerUser
                Guid
            }
        }`,
        ProductManagerUsersCount: gql`
        query productManagerUsersCount {
            productManagerUsersCount
        }`,
        DashboardUsers: gql`
        query dashboardUsers($filter: String, $offset: Int, $limit: Int) {
            dashboardUsers(filter: $filter, offset: $offset, limit: $limit) {
                Id
                Email
                FirstName
                LastName
                CreatedAt
                UpdatedAt
                IsDashboardUser
                IsProductManagerUser
                Guid
            }
        }`,
        DashboardUsersCount: gql`
        query dashboardUsersCount($filter: String) {
            dashboardUsersCount(filter: $filter)
        }`,
        User: gql`
        query user($id: ID, $isDashboardUser: Boolean, $isProductManagerUser: Boolean) {
            user(id: $id, isDashboardUser: $isDashboardUser, isProductManagerUser: $isProductManagerUser) {
                Id
                Email
                FirstName
                LastName
                CreatedAt
                UpdatedAt
                IsDashboardUser
                IsProductManagerUser
                Guid
            }
        }`,
        UserByGuid: gql`
        query userByGuid($guid: String) {
            userByGuid(guid: $guid) {
                Id
                Email
                FirstName
                LastName
                CreatedAt
                UpdatedAt
                IsDashboardUser
                IsProductManagerUser
                Guid
            }
        }`,
        UsersByHostPoolId: gql`
        query usersByHostPoolId($hostPoolId: ID) {
            usersByHostPoolId(hostPoolId: $hostPoolId) {
                Id
                Email
                FirstName
                LastName
                CreatedAt
                UpdatedAt
                IsDashboardUser
                IsProductManagerUser
                Guid
            }
        }`,
        UsersByHostId: gql`
        query usersByHostId($hostId: ID) {
            usersByHostId(hostId: $hostId) {
                Id
                Email
                FirstName
                LastName
                CreatedAt
                UpdatedAt
                IsDashboardUser
                IsProductManagerUser
                Guid
            }
        }`,
        UserByEmail: gql`
        query userByEmail($email: String) {
            userByEmail(email: $email) {
                Id
                Email
                FirstName
                LastName
                CreatedAt
                UpdatedAt
                IsDashboardUser
                IsProductManagerUser
                Guid
            }
        }`,
    },
    Mutations: {
        SaveUser: gql`
        mutation saveUser($user: UsersInput, $permissionIds: [ID], $hostIds: [ID], $isDashboardUser: Boolean, $isProductManagerUser: Boolean) {
            saveUser(user: $user, permissionIds: $permissionIds, hostIds: $hostIds, isDashboardUser: $isDashboardUser, isProductManagerUser: $isProductManagerUser) {
                IsSuccess
                Message
                StringData
            }
        }`,
        SendResetUserPasswordEmail: gql`
        mutation sendResetUserPasswordEmail($email: String) {
            sendResetUserPasswordEmail(email: $email) {
                IsSuccess
                Message
                StringData
            }
        }`,
    },
};
