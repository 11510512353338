<template lang="pug">
.moloss-category-search-select-list-item
  font-awesome-icon.mr-2(:icon="['fas', 'layer-group']")
  span {{ molossCategory.id }}: {{ molossCategory.Name }} {{ categoryUrl ? `<${categoryUrl.UrlPath}>` : '' }}
  font-awesome-icon.cursor-pointer.ml-2(icon='trash' @click="deselect(molossCategory)" v-if="deselect")

</template>

<script>
export default {
  name: "MolossCategorySearchSelectListItem",
  props: {
    molossCategory: {
      type: Object,
      required: true,
    },
    deselect: {
      type: Function,
      required: false,
    },
  },
  computed: {
    categoryUrl() {
      try {
        return this.molossCategory.CategoryUrls.find(
          (c) => c.SystemLanguageId == 1
        );
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
  },
};
</script>

<style scoped></style>
