// @ts-ignore
import {Notifications} from "../../../graphql/notifications/Notifications.ts";

export class NotificationsComponent {
    async getNotifications(
        apollo,
        userId,
        hostIds,
        productId,
        createdBy,
        notificationPriorityIds,
        notificationTypeId,
        isSeen,
        isDone,
        from,
        to,
        offset,
        limit
    ) {
        try {
            const notifications = await apollo
                .query({
                    query: Notifications.Queries.Notifications,
                    fetchPolicy: "no-cache",
                    variables: {
                        userId: userId,
                        hostIds: hostIds,
                        productId: productId,
                        createdBy: createdBy,
                        notificationPriorityIds: notificationPriorityIds,
                        notificationTypeId: notificationTypeId,
                        isSeen: isSeen,
                        isDone: isDone,
                        from: from,
                        to: to,
                        offset: offset,
                        limit: limit,
                    },
                })
                .then(({data}) => data?.notifications)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!notifications) {
                return undefined;
            }
            return notifications;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getNotificationsCount(
        apollo,
        userId,
        hostIds,
        productId,
        createdBy,
        notificationPriorityIds,
        notificationTypeId,
        isSeen,
        isDone,
        from,
        to
    ) {
        try {
            const notificationsCount = await apollo
                .query({
                    query: Notifications.Queries.NotificationsCount,
                    fetchPolicy: "no-cache",
                    variables: {
                        userId: userId,
                        hostIds: hostIds,
                        productId: productId,
                        createdBy: createdBy,
                        notificationPriorityIds: notificationPriorityIds,
                        notificationTypeId: notificationTypeId,
                        isSeen: isSeen,
                        isDone: isDone,
                        from: from,
                        to: to,
                    },
                })
                .then(({data}) => data?.notificationsCount)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (notificationsCount == undefined) {
                return undefined;
            }
            return notificationsCount;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getNotification(apollo, id) {
        try {
            if (!apollo) {
                return undefined;
            }
            if (!id) {
                return undefined;
            }
            const notification = await apollo.query({
                query: Notifications.Queries.Notification,
                fetchPolicy: "no-cache",
                variables: {
                    id: id,
                },
            })
                .then(({data}) => data?.notification)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!notification) {
                return undefined;
            }
            return notification;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async setAllNotificationsAsDone(apollo, doneBy, userId) {
        try {
            if (!apollo) {
                return {
                    IsSuccess: false,
                    Message: `Kein Apollo-Client gefunden.`,
                };
            }
            if (!doneBy) {
                return {
                    IsSuccess: false,
                    Message: "Kein 'erledigt von' gefunden.",
                };
            }
            if (!userId) {
                return {
                    IsSuccess: false,
                    Message: "Keine Benutzer-Id gefunden.",
                };
            }
            const setAllNotificationsAsDone = await apollo.mutate({
                mutation: Notifications.Mutations.SetAllNotificationsAsDone,
                fetchPolicy: "no-cache",
                variables: {
                    doneBy: doneBy,
                    userId: userId,
                },
            })
                .then(({data}) => data?.setAllNotificationsAsDone)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!setAllNotificationsAsDone) {
                return {
                    IsSuccess: false,
                    Message: "Die Benachrichtigungen konnte nicht als erledigt markiert werden.",
                };
            }
            return setAllNotificationsAsDone;
        } catch (e) {
            console.error(e);
            return {
                IsSuccess: false,
                Message: e.message,
            };
        }
    }

    async saveNotification(apollo, notification) {
        try {
            if (!apollo) {
                return {
                    IsSuccess: false,
                    Message: "Kein Apollo-Client gefunden.",
                };
            }
            if (!notification) {
                return {
                    IsSuccess: false,
                    Message: "Keine Benachrichtigung gefunden.",
                };
            }
            if (!notification.Title) {
                return {
                    IsSuccess: false,
                    Message: "Kein Titel gefunden.",
                };
            }
            const savedNotification = await apollo.mutate({
                mutation: Notifications.Mutations.SaveNotification,
                fetchPolicy: "no-cache",
                variables: {
                    notification: notification,
                },
            })
                .then(({data}) => data?.saveNotification)
                .catch((e) => {
                    console.error(e);
                    return {
                        IsSuccess: false,
                        Message: e.message,
                    };
                });
            if (!savedNotification) {
                return {
                    IsSuccess: false,
                    Message: "Die Benachrichtigung konnte nicht gespeichert werden.",
                };
            }
            return savedNotification;
        } catch (e) {
            console.error(e);
            return {
                IsSuccess: false,
                Message: e.message,
            };
        }
    }
}
