<template lang="pug">
  .row
    .col-6
      .section-headline Verträge
    .col-6
      .float-right
        .form-inline.mr-3
          .form-check
            input.form-check-input(type="radio" :id="ProductContractTypesEnum.Contract" :value="ProductContractTypesEnum.Contract" v-model="productContractTypeId" @change="filterProductContracts")
            label.form-check-label(:for="ProductContractTypesEnum.Contract") Vertrag
          .form-check.ml-2
            input.form-check-input(type="radio" :id="ProductContractTypesEnum.Restock" :value="ProductContractTypesEnum.Restock" v-model="productContractTypeId" @change="filterProductContracts")
            label.form-check-label(:for="ProductContractTypesEnum.Restock") Aufstockung
          .form-check.ml-2
            input.form-check-input(type="radio" :id="ProductContractTypesEnum.Amendment" :value="ProductContractTypesEnum.Amendment" v-model="productContractTypeId" @change="filterProductContracts")
            label.form-check-label(:for="ProductContractTypesEnum.Amendment") Änderungsvertrag
          .form-check.ml-2
            input.form-check-input(type="radio" :id="ProductContractTypesEnum.FrameworkAgreement" :value="ProductContractTypesEnum.FrameworkAgreement" v-model="productContractTypeId" @change="filterProductContracts")
            label.form-check-label(:for="ProductContractTypesEnum.FrameworkAgreement") Rahmenvertrag
    .col-12
      .table-responsive
        table.table.table-bordered
          thead
            tr
              th(scope='col') Id
              th(scope='col') Vertragsnummer
              th(scope='col') Name
              th(scope='col') Variante(n)
              th(scope='col') Status
              th(scope='col') erstellt am
              th(scope='col') zuletzt aktualisiert
              th(scope='col') zuletzt aktualisiert von
              th(scope='col') Notiz
              th(scope='col') #
          tbody
            product-contract-list-row(
              v-for="productContract in productContracts"
              :product-contract="productContract"
              :host-id="hostId"
              :key="productContract.Id"
            )
      pagination(:load="loadProductContracts" :overall-count="productContractsCount" :offset="offset" :limit="limit")

</template>

<script>
import EventBus from "../../event-bus";
import Pagination from "@/views/partials/pagination.vue";
import { ProductContractsComponent } from "@/lib/components/productContracts/ProductContractsComponent.ts";
import { ProductContractTypesEnum } from "@/utils/enums/productContractTypes/ProductContractTypesEnum.ts";
import ProductContractListRow from "@/views/productContracts/ProductContractListRow.vue";

export default {
  name: "ProductContractsList",
  props: {
    hostId: {
      type: String,
      required: false,
    },
    productId: {
      type: String,
      required: false,
    },
    userId: {
      type: String,
      required: false,
    },
  },
  components: {
    ProductContractListRow,
    Pagination,
  },
  async mounted() {
    try {
      if (this.$router.currentRoute.query.offset) {
        this.offset = parseInt(this.$router.currentRoute.query.offset);
      }
      this.loadProductContracts();
      this.loadProductContractsCount();
      EventBus.$on(
        "updateContractsList",
        async function () {
          this.productContracts = [];
          this.loadProductContracts();
          this.loadProductContractsCount();
        }.bind(this)
      );
    } catch (e) {
      console.error(e);
      this.$alert(e.message);
    }
  },
  data() {
    return {
      productContracts: [],
      productContractsCount: 0,
      productContractTypeId: ProductContractTypesEnum.Contract,

      offset: 0,
      limit: 15,

      ProductContractTypesEnum: ProductContractTypesEnum,
    };
  },
  methods: {
    async filterProductContracts() {
      try {
        this.productContracts = [];
        this.offset = 0;
        this.loadProductContracts();
        this.loadProductContractsCount();
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    async loadProductContracts(offset) {
      try {
        if (offset != undefined) {
          this.offset = offset;
        }
        const productContractsComponent = new ProductContractsComponent();
        EventBus.$emit("changeLoadingState", true);
        const productContracts =
          await productContractsComponent.getProductContracts(
            this.$apollo,
            this.hostId,
            this.productId,
            this.userId,
            undefined,
            undefined,
            [this.productContractTypeId],
            this.offset,
            this.limit
          );
        EventBus.$emit("changeLoadingState", false);
        if (!productContracts) {
          return;
        }
        this.productContracts = productContracts;
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    async loadProductContractsCount() {
      try {
        const productContractsComponent = new ProductContractsComponent();
        const productContractsCount =
          await productContractsComponent.getProductContractsCount(
            this.$apollo,
            this.hostId,
            this.productId,
            this.userId,
            undefined,
            undefined,
            this.productContractTypeId
          );
        if (productContractsCount == undefined) {
          return;
        }
        this.productContractsCount = productContractsCount;
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
  },
};
</script>
