<template lang="pug">
  .user-search-select-list-item
    font-awesome-icon.mr-2(:icon="['fas', 'user']")
    span {{ user.FirstName }} {{ user.LastName }} <{{ user.Email }}>
    font-awesome-icon.cursor-pointer.ml-2(icon='trash' @click="deselect(user)" v-if="deselect")

</template>

<script>
export default {
  name: "UserSearchSelectListItem",
  props: {
    user: {
      type: Object,
      required: true,
    },
    deselect: {
      type: Function,
      required: false,
    },
  },
};
</script>

<style scoped></style>
