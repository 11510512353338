// @ts-ignore
import {Users} from "../../../graphql/users/Users.ts";

export class UsersComponent {
  async getUser(apollo, id) {
    try {
      if (!apollo) {
        return undefined;
      }
      if (!id) {
        return undefined;
      }
      const user = await apollo
        .query({
          query: Users.Queries.User,
          variables: {
            id: id,
          },
        })
        .then(({ data }) => data?.user)
        .catch((e) => {
          console.error(e);
          return undefined;
        });
      if (!user) {
        return undefined;
      }
      return user;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }

  async getUsersByHostId(apollo, hostId) {
    try {
      if (!apollo) {
        return undefined;
      }
      if (!hostId) {
        return undefined;
      }
      const users = await apollo
        .query({
          query: Users.Queries.UsersByHostId,
          fetchPolicy: "no-cache",
          variables: {
            hostId: hostId,
          },
        })
        .then(({ data }) => data?.usersByHostId)
        .catch((e) => {
          console.error(e);
          return undefined;
        });
      if (!users) {
        return undefined;
      }
      return users;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }

  async getProductManagerUsers(apollo, filter, offset, limit) {
    try {
      if (!apollo) {
        return undefined;
      }
      const users = await apollo.query({
        query: Users.Queries.ProductManagerUsers,
        fetchPolicy: "no-cache",
        variables: {
          filter: filter,
          offset: offset,
          limit: limit,
        },
      })
          .then(({data}) => data?.productManagerUsers)
          .catch((e) => {
            console.error(e);
            return undefined;
          });
      if (!users) {
        return undefined;
      }
      return users;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }
}
