<template lang="pug">
tr
  td {{ productContract.Id }}
  td {{ productContract.ProductContractNumber }}
  td {{ productContract.ProductContractName }}
  td
    template(v-if="!productContract.IsRestock")
      template(v-for="productVariant in productVariants")
        router-link(:to="{ name: 'ProductDraftDetails', params: { hostId: localHostId, productDraftId: productVariant.ProductDraftId } }" target="_blank" v-if="productContract.ProductContractStatusId != ProductContractStatusEnum.Confirmed && host")
          p {{ productVariant.ProductVariantNumber }}
        p(v-else) {{ productVariant.ProductVariantNumber }}

    template(v-else)
      router-link(:to="{ name: 'ProductDetails', params: { hostId: localHostId, productId: product.Id } }" target="_blank" v-for="product in products")
        p {{ product.Id }} ({{ product.Uebernachtungen }} ÜN)
  td.status
    span.blue(v-if="productContract.ProductContractStatusId == ProductContractStatusEnum.Draft") Entwurf
    span.send(v-else-if="productContract.ProductContractStatusId == ProductContractStatusEnum.Sent") versendet
    span.green(v-else-if="productContract.ProductContractStatusId == ProductContractStatusEnum.Confirmed") bestätigt
    span.red(v-else-if="productContract.ProductContractStatusId == ProductContractStatusEnum.Rejected") abgelehnt
    span.gray(v-else-if="productContract.ProductContractStatusId == ProductContractStatusEnum.Revoked") zurückgezogen
  td {{ productContract.CreatedAt | formatDateTime }}
  td {{ editDate | formatDateTime }}
  td
    span(v-if="user") {{ user.FirstName }} {{ user.LastName }}
  td {{ notice }}
  td
    .d-flex
      router-link.button.button-primary.button-tdays(:to="{ name: 'ProductContractDetails', params: { productContractId: productContract.Id, hostId: localHostId } }" v-if="localHostId")
        font-awesome-icon(:icon="['fas', 'arrow-up-right-from-square']")
        span.ml-2 öffnen
      button.button.button-primary.button-tdays.ml-2(@click="reuploadProductContract")
        font-awesome-icon(:icon="['fas', 'upload']")
        span.ml-2 Vertrag
      button.button.button-primary.button-tdays.ml-2(@click="reuploadGeneralTermsAndConditions")
        font-awesome-icon(:icon="['fas', 'upload']")
        span.ml-2 AGB

</template>

<script>
import { ProductContractStatusEnum } from "@/utils/enums/productContractStatus/ProductContractStatusEnum.ts";
import { ProductVariants } from "@/graphql/productVariants/ProductVariants.ts";
import { Hosts } from "@/graphql/hosts/Hosts.ts";
import { Users } from "@/graphql/users/Users.ts";
import { ProductContractTypesEnum } from "@/utils/enums/productContractTypes/ProductContractTypesEnum.ts";
import { ProductsComponent } from "@/lib/components/products/ProductsComponent.ts";
import { ProductContractsComponent } from "@/lib/components/productContracts/ProductContractsComponent.ts";
import EventBus from "@/event-bus";

export default {
  name: "ProductContractListRow",
  props: {
    productContract: {
      type: Object,
      required: true,
    },
    hostId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      productVariants: [],
      products: [],
      host: undefined,
      user: undefined,
      ProductContractStatusEnum: ProductContractStatusEnum,
      ProductContractTypesEnum: ProductContractTypesEnum,
    };
  },
  async mounted() {
    try {
      if (!this.hostId) {
        this.loadHost();
      }
      if (this.productContract.IsRestock) {
        this.loadProducts();
      } else {
        this.loadProductVariants();
      }
      this.loadUser();
    } catch (e) {
      console.log(e);
      this.$alert(e.message);
    }
  },
  methods: {
    async loadProductVariants() {
      try {
        const productVariants = await new Promise((resolve, reject) => {
          this.$apollo
            .query({
              query: ProductVariants.Queries.ProductVariantsByProductContractId,
              fetchPolicy: "no-cache",
              variables: {
                productContractId: this.productContract.Id,
              },
            })
            .then(({ data }) => {
              if (!data || !data.productVariantsByProductContractId) {
                reject();
              }
              resolve(data.productVariantsByProductContractId);
            })
            .catch((e) => {
              console.log(e);
              this.$alert(e.message);
              reject();
            });
        });
        if (!productVariants) {
          return;
        }
        this.productVariants = productVariants;
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    async loadProducts() {
      try {
        const productsComponent = new ProductsComponent();
        const products = await productsComponent.getProducts(
          this.$apollo,
          undefined,
          undefined,
          undefined,
          undefined,
          this.productContract.Id
        );
        if (!products) {
          return;
        }
        this.products = products;
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    async loadHost() {
      try {
        const host = await this.$apollo
          .query({
            query: Hosts.Queries.HostByProductContractId,
            variables: {
              productContractId: this.productContract.Id,
            },
          })
          .then(({ data }) => data?.hostByProductContractId)
          .catch((e) => {
            console.error(e);
            return undefined;
          });
        if (!host) {
          return;
        }
        this.host = host;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadUser() {
      try {
        const user = await this.$apollo
          .query({
            query: Users.Queries.User,
            fetchPolicy: "no-cache",
            variables: {
              id: this.editorId,
            },
          })
          .then(({ data }) => data?.user)
          .catch((e) => {
            console.error(e);
            return undefined;
          });
        if (!user) {
          return;
        }
        this.user = user;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async reuploadProductContract() {
      try {
        const confirmed = await this.$confirm(
          "Sind Sie sicher, dass Sie den Vertrag erneut hochladen möchten?"
        )
          .then()
          .catch((e) => e);
        if (!confirmed) {
          return;
        }
        const productContractsComponent = new ProductContractsComponent();
        EventBus.$emit("changeLoadingState", true);
        const reuploadedProductContract =
          await productContractsComponent.reuploadProductContract(
            this.$apollo,
            this.productContract.Id
          );
        EventBus.$emit("changeLoadingState", false);
        if (!reuploadedProductContract) {
          return this.$alert(
            "Der Vertrag konnte nicht erneut hochgeladen werden."
          );
        }
        if (reuploadedProductContract.Message) {
          reuploadedProductContract.IsSuccess
            ? this.$swal(reuploadedProductContract.Message)
            : this.$alert(reuploadedProductContract.Message);
        }
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async reuploadGeneralTermsAndConditions() {
      try {
        const confirmed = await this.$confirm(
          "Sind Sie sicher, dass Sie die AGB erneut hochladen möchten?"
        )
          .then()
          .catch((e) => e);
        if (!confirmed) {
          return;
        }
        const productContractsComponent = new ProductContractsComponent();
        EventBus.$emit("changeLoadingState", true);
        const reuploadedGeneralTermsAndConditions =
          await productContractsComponent.reuploadGeneralTermsAndConditions(
            this.$apollo,
            this.productContract.Id
          );
        EventBus.$emit("changeLoadingState", false);
        if (!reuploadedGeneralTermsAndConditions) {
          return this.$alert(
            "Die AGB konnten nicht erneut hochgeladen werden."
          );
        }
        if (reuploadedGeneralTermsAndConditions.Message) {
          reuploadedGeneralTermsAndConditions.IsSuccess
            ? this.$swal(reuploadedGeneralTermsAndConditions.Message)
            : this.$alert(reuploadedGeneralTermsAndConditions.Message);
        }
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
  computed: {
    editDate() {
      try {
        if (!this.productContract) {
          return undefined;
        }
        if (
          this.productContract.ProductContractStatusId ==
          ProductContractStatusEnum.Sent
        ) {
          return this.productContract.SentAt;
        } else if (
          this.productContract.ProductContractStatusId ==
          ProductContractStatusEnum.Revoked
        ) {
          return this.productContract.RevokedAt;
        } else if (
          this.productContract.ProductContractStatusId ==
          ProductContractStatusEnum.Confirmed
        ) {
          return this.productContract.ConfirmedAt;
        }
        return undefined;
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    editorId() {
      try {
        if (!this.productContract) {
          return undefined;
        }
        if (
          this.productContract.ProductContractStatusId ==
          ProductContractStatusEnum.Sent
        ) {
          return this.productContract.SentBy;
        } else if (
          this.productContract.ProductContractStatusId ==
          ProductContractStatusEnum.Revoked
        ) {
          return this.productContract.RevokedBy;
        } else if (
          this.productContract.ProductContractStatusId ==
          ProductContractStatusEnum.Confirmed
        ) {
          return this.productContract.ConfirmedBy;
        }
        return undefined;
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    notice() {
      try {
        if (!this.productContract) {
          return undefined;
        }
        if (
          this.productContract.ProductContractStatusId ==
          ProductContractStatusEnum.Sent
        ) {
          return this.productContract.SendNotice;
        } else if (
          this.productContract.ProductContractStatusId ==
          ProductContractStatusEnum.Revoked
        ) {
          return this.productContract.RevokationNotice;
        } else {
          return this.productContract.SendNotice;
        }
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    localHostId() {
      try {
        if (this.hostId) {
          return this.hostId;
        }
        return this.host?.Id;
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
  },
};
</script>

<style scoped></style>
