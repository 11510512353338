import { render, staticRenderFns } from "./NotificationsModal.vue?vue&type=template&id=ea283606&scoped=true&lang=pug"
import script from "./NotificationsModal.vue?vue&type=script&lang=js"
export * from "./NotificationsModal.vue?vue&type=script&lang=js"
import style0 from "./NotificationsModal.vue?vue&type=style&index=0&id=ea283606&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ea283606",
  null
  
)

export default component.exports