<template lang="pug">
  .host-search-select-list-item
    router-link(:to="{ name: 'HostDetails', params: { hostId: host.Id } }" target="_blank")
      font-awesome-icon.mr-2(:icon="['fas', 'house']")
      span {{ host.Id }}: {{ host.Name }}
      span(v-if="host.EMAIL1") &nbsp;<{{ host.EMAIL1 }}>
    font-awesome-icon.cursor-pointer.ml-2(icon='trash' @click="deselect(host)" v-if="deselect")

</template>

<script>
export default {
  name: "HostSearchSelectListItem",
  props: {
    host: {
      type: Object,
      required: true,
    },
    deselect: {
      type: Function,
      required: false,
    },
  },
};
</script>

<style scoped>
a {
  color: #fff !important;
}

a:hover {
  text-decoration: underline;
}
</style>
