// @ts-ignore
import {Hosts} from "../../../graphql/hosts/Hosts.ts";

export class HostsComponent {
    async getHosts(apollo, filter, activeProducts, offset, limit) {
        try {
            if (!apollo) {
                return undefined;
            }
            const hosts = await apollo
                .query({
                    query: Hosts.Queries.Hosts,
                    fetchPolicy: "no-cache",
                    variables: {
                        filterValue: filter,
                        hasToHaveActiveProducts: activeProducts,
                        offset: offset,
                        limit: limit,
                    },
                })
                .then(({data}) => data?.hosts)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!hosts) {
                return undefined;
            }
            return hosts;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getHost(apollo, hostId) {
        try {
            if (!apollo) {
                return undefined;
            }
            if (!hostId) {
                return undefined;
            }
            const host = await apollo
                .query({
                    query: Hosts.Queries.Host,
                    variables: {
                        id: hostId,
                    },
                })
                .then(({data}) => data?.host)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!host) {
                return undefined;
            }
            return host;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getHostsByEmail(apollo, email, offset, limit) {
        try {
            if (!apollo) {
                return undefined;
            }
            const hosts = await apollo.query({
                query: Hosts.Queries.HostsByEmail,
                variables: {
                    email: email,
                    offset: offset,
                    limit: limit,
                },
            })
                .then(({data}) => data?.hostsByEmail)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!hosts) {
                return undefined;
            }
            return hosts;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getHostsByProductStatusIds(apollo, productStatusIds, offset, limit) {
        try {
            if (!apollo) {
                return undefined;
            }
            if (!productStatusIds) {
                return undefined;
            }
            const hosts = await apollo.query({
                query: Hosts.Queries.HostsByProductStatusIds,
                fetchPolicy: "no-cache",
                variables: {
                    productStatusIds: productStatusIds,
                    offset: offset,
                    limit: limit,
                },
            })
                .then(({data}) => data?.hostsByProductStatusIds)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!hosts) {
                return undefined;
            }
            return hosts;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }
}
