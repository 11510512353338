<template lang="pug">
  .container-fluid.p-5
    .row
    .col-12
      .section-headline Benachrichtigungen
    .col-12
      ul.nav.nav-tabs.mb-4
        li.nav-item
          a.nav-link.rounded-0.active(data-toggle='tab' href='#notificationsForMe' role='tab' aria-selected='true') für mich
        li.nav-item
          a.nav-link.rounded-0(data-toggle='tab' href='#notificationsByMe' role='tab' aria-selected='true') von mir
      .tab-content
        #notificationsForMe.tab-pane.show.active
          .container-fluid
            notifications-table(
              :user-id="userId"
              :product-id="productId"
              :host-id="hostId"
              :open-notification="openNotificationDetailsModal"
            )

        #notificationsByMe.tab-pane
          .container-fluid
            notifications-table(
              :created-by="userId"
              :product-id="productId"
              :host-id="hostId"
              :open-notification="openNotificationDetailsModal"
            )

</template>

<script>
import moment from "moment";
import Pagination from "@/views/partials/pagination";
import EventBus from "@/event-bus";
import { NotificationTypesEnum } from "@/utils/enums/notificationTypes/NotificationTypesEnum.ts";
import NotificationsTable from "@/views/notifications/NotificationsTable.vue";
import { NotificationsComponent } from "@/lib/components/notifications/NotificationsComponent.ts";
import NotificationDetailsModal from "@/views/notifications/NotificationDetailsModal.vue";

export default {
  name: "NotificationsModal",
  components: { NotificationsTable, Pagination },
  props: {
    userId: {
      type: String,
      required: false,
    },
    hostId: {
      type: String,
      required: false,
    },
    productId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      notifications: [],
      notificationsCount: 0,

      offset: 0,
      limit: 10,
      moment: moment,
    };
  },
  mounted() {
    try {
      EventBus.$on(
        "updateNotifications",
        function () {
          this.notifications = [];
          this.offset = 0;
          this.loadNotifications();
          this.loadNotificationsCount();
        }.bind(this)
      );
      this.loadNotifications();
      this.loadNotificationsCount();
    } catch (e) {
      console.log(e);
      this.$alert(e.message);
    }
  },
  methods: {
    async loadNotifications() {
      try {
        const notificationsComponent = new NotificationsComponent();
        EventBus.$emit("changeLoadingState", true);
        const notifications = await notificationsComponent.getNotifications(
          this.$apollo,
          this.userId,
          this.hostId ? [this.hostId] : undefined,
          this.productId,
          undefined,
          undefined,
          NotificationTypesEnum.ProductManager,
          undefined,
          false,
          undefined,
          undefined,
          this.offset,
          this.limit
        );
        EventBus.$emit("changeLoadingState", false);
        if (!notifications) {
          return this.$alert(
            "Es konnten keine Benachrichtigungen geladen werden."
          );
        }
        this.notifications = notifications;
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    async loadNotificationsCount() {
      try {
        const notificationsComponent = new NotificationsComponent();
        const notificationsCount =
          await notificationsComponent.getNotificationsCount(
            this.$apollo,
            this.userId,
            this.hostId ? [this.hostId] : undefined,
            this.productId,
            undefined,
            undefined,
            NotificationTypesEnum.ProductManager,
            undefined,
            false
          );
        if (notificationsCount == undefined) {
          return undefined;
        }
        this.notificationsCount = notificationsCount;
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    openNotificationDetailsModal(notificationId, hostId, productId, date) {
      try {
        this.$modal.show(
          NotificationDetailsModal,
          {
            notificationId: notificationId,
            hostId: hostId,
            productId: productId,
            date: date,
          },
          {
            height: "auto",
            classes: ["rounded-0", "w-auto"],
          }
        );
        this.$emit("close");
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.scrollable {
  max-height: 500px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}
</style>
