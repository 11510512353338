<template lang="pug">
.container.p-4
  notification(:notification-id="notificationId")

</template>

<script>
import Notification from "@/views/notifications/partials/Notification.vue";

export default {
  name: "NotificationDetails",
  components: { Notification },
  props: {
    notificationId: {
      type: [String, Number],
      required: false,
    },
  },
};
</script>

<style scoped></style>
