// @ts-ignore
import {Mails} from "../../../graphql/mails/Mails.ts";
// @ts-ignore
import {MailboxFoldersEnum} from "../../../utils/enums/mailboxFolders/MailboxFoldersEnum.ts";

export class MailsComponent {
    async sendMail(apollo, mail, attachments) {
        try {
            if (!apollo) {
                return {
                    IsSuccess: false,
                    Message: "Kein Apollo-Client gefunden.",
                };
            }
            if (!mail) {
                return {
                    IsSuccess: false,
                    Message: "Keine Mail gefunden.",
                };
            }
            const sentMail = await apollo.mutate({
                mutation: Mails.Mutations.SendMail,
                fetchPolicy: "no-cache",
                variables: {
                    mail: mail,
                    attachments: attachments,
                },
            })
                .then(({data}) => data?.sendMail)
                .catch((e) => {
                    console.error(e);
                    return {
                        IsSuccess: false,
                        Message: e.message,
                    };
                });
            if (!sentMail) {
                return {
                    IsSuccess: false,
                    Message: "Die Mail konnte nicht gespeichert werden.",
                };
            }
            return sentMail;
        } catch (e) {
            console.error(e);
            return {
                IsSuccess: false,
                Message: e.message,
            };
        }
    }

    async saveMail(apollo, mail) {
        try {
            if (!apollo) {
                return {
                    IsSuccess: false,
                    Message: "Kein Apollo-Client gefunden.",
                };
            }
            if (!mail) {
                return {
                    IsSuccess: false,
                    Message: "Keine Mail gefunden.",
                };
            }
            const savedMail = await apollo.mutate({
                mutation: Mails.Mutations.SaveMail,
                fetchPolicy: "no-cache",
                variables: {
                    mail: mail,
                },
            })
                .then(({data}) => data?.saveMail)
                .catch((e) => {
                    console.error(e);
                    return {
                        IsSuccess: false,
                        Message: e.message,
                    };
                });
            if (!savedMail) {
                return {
                    IsSuccess: false,
                    Message: "Die Mail konnte nicht gespeichert werden.",
                };
            }
            return savedMail;
        } catch (e) {
            console.error(e);
            return {
                IsSuccess: false,
                Message: e.message,
            };
        }
    }

    async getMail(apollo, id) {
        try {
            if (!apollo) {
                return undefined;
            }
            if (!id) {
                return undefined;
            }
            const mail = await apollo.query({
                query: Mails.Queries.Mail,
                fetchPolicy: "no-cache",
                variables: {
                    id: id,
                },
            })
                .then(({data}) => data?.mail)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!mail) {
                return undefined;
            }
            return mail;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getMails(apollo, mailboxId, mailboxFolderId, mailTypeId, filter, mailFrom, mailTo, receivedFrom, receivedTo, isSeen, seenBy, offset, limit) {
        try {
            if (!apollo) {
                return undefined;
            }
            let isDeleted = false;
            if (mailboxFolderId == MailboxFoldersEnum.Inbox) {
                mailboxFolderId = null;
            } else if (mailboxFolderId == MailboxFoldersEnum.InboxDeleted) {
                mailboxFolderId = null;
                isDeleted = true;
            } else if (mailboxFolderId == MailboxFoldersEnum.Outgoing || mailboxFolderId == MailboxFoldersEnum.OutgoingSent) {
                mailboxFolderId = null;
            }
            const mails = await apollo.query({
                query: Mails.Queries.Mails,
                fetchPolicy: "no-cache",
                variables: {
                    mailboxId: mailboxId,
                    mailboxFolderId: mailboxFolderId,
                    mailTypeId: mailTypeId,
                    filter: filter,
                    mailFrom: mailFrom,
                    mailTo: mailTo,
                    receivedFrom: receivedFrom,
                    receivedTo: receivedTo,
                    isDeleted: isDeleted,
                    isSeen: isSeen,
                    seenBy: seenBy,
                    offset: offset,
                    limit: limit,
                },
            })
                .then(({data}) => data?.mails)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!mails) {
                return undefined;
            }
            return mails;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getMailsCount(apollo, mailboxId, mailboxFolderId, mailTypeId, filter, mailFrom, mailTo, receivedFrom, receivedTo, isSeen, seenBy) {
        try {
            if (!apollo) {
                return undefined;
            }
            let isDeleted = false;
            if (mailboxFolderId == MailboxFoldersEnum.Inbox) {
                mailboxFolderId = null;
            } else if (mailboxFolderId == MailboxFoldersEnum.InboxDeleted) {
                mailboxFolderId = undefined;
                isDeleted = true;
            } else if (mailboxFolderId == MailboxFoldersEnum.Outgoing || mailboxFolderId == MailboxFoldersEnum.OutgoingSent) {
                mailboxFolderId = null;
            }
            const mailsCount = await apollo.query({
                query: Mails.Queries.MailsCount,
                fetchPolicy: "no-cache",
                variables: {
                    mailboxId: mailboxId,
                    mailboxFolderId: mailboxFolderId,
                    mailTypeId: mailTypeId,
                    filter: filter,
                    mailFrom: mailFrom,
                    mailTo: mailTo,
                    receivedFrom: receivedFrom,
                    receivedTo: receivedTo,
                    isDeleted: isDeleted,
                    isSeen: isSeen,
                    seenBy: seenBy,
                },
            })
                .then(({data}) => data?.mailsCount)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (mailsCount == undefined) {
                return undefined;
            }
            return mailsCount;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getMailHtml(apollo, userId, mailboxId, mailId) {
        try {
            if (!apollo) {
                return undefined;
            }
            if (!userId) {
                return undefined;
            }
            if (!mailboxId) {
                return undefined;
            }
            const mailHtml = await apollo.query({
                query: Mails.Queries.MailHtml,
                fetchPolicy: "no-cache",
                variables: {
                    userId: userId,
                    mailboxId: mailboxId,
                    mailId: mailId,
                },
            })
                .then(({data}) => data?.mailHtml)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!mailHtml) {
                return undefined;
            }
            return mailHtml;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getMailByReplyToMailId(apollo, replyToMailId) {
        try {
            if (!apollo) {
                return undefined;
            }
            if (!replyToMailId) {
                return undefined;
            }
            const mail = await apollo.query({
                query: Mails.Queries.MailByReplyToMailId,
                fetchPolicy: "no-cache",
                variables: {
                    replyToMailId: replyToMailId,
                },
            })
                .then(({data}) => data?.mailByReplyToMailId)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!mail) {
                return undefined;
            }
            return mail;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getMailByForwardOfMailId(apollo, forwardOfMailId) {
        try {
            if (!apollo) {
                return undefined;
            }
            if (!forwardOfMailId) {
                return undefined;
            }
            const mail = await apollo.query({
                query: Mails.Queries.MailByForwardOfMailId,
                fetchPolicy: "no-cache",
                variables: {
                    forwardOfMailId: forwardOfMailId,
                },
            })
                .then(({data}) => data?.mailByForwardOfMailId)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!mail) {
                return undefined;
            }
            return mail;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getReferencedMails(apollo, mailId) {
        try {
            if (!apollo) {
                return undefined;
            }
            if (!mailId) {
                return undefined;
            }
            const referencedMails = await apollo.query({
                query: Mails.Queries.ReferencedMails,
                fetchPolicy: "no-cache",
                variables: {
                    mailId: mailId,
                },
            })
                .then(({data}) => data?.referencedMails)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!referencedMails) {
                return undefined;
            }
            return referencedMails;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }
}
