import gql from "graphql-tag";

export const Platforms = {
    Queries: {
        Platforms: gql`
        query plaforms {
            platforms {
                Id
                Name
            }
        }`,
        Platform: gql`
        query plaform($id: ID) {
            platform(id: $id) {
                Id
                Name
            }
        }`,
    }
};
