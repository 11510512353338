<template lang="pug">
  .container-fluid.p-5
    .row
      .col-12
        .section-headline Rückruf bearbeiten
      .col-12.mt-2
        label.form-label Bemerkung
        textarea-autosize.form-control(v-model="callback.Reason")
      .col-12.mt-2
        label.form-label Name
        input.form-control(type='text' v-model="callback.Name")
      .col-12.mt-2
        label.form-label Kontaktadresse
        input.form-control(type='text' v-model="callback.Phone")
      .col-12.mt-2
        label.form-label Rückruf von/bis
        input.form-control(type='text' v-model="callback.CallbackTo")
      .col-6.mt-2
        label.form-label Gastgeber
        input.form-control(type='text' v-model="callback.HostId")
      .col-6.mt-2
        label.form-label Gutschein
        input.form-control(type='text' v-model="callback.VoucherId")
      .col-6.mt-2
        label.form-label Erledigt am
        input.form-control(type='text' :value="callback.DoneAt | formatDateTime" disabled)
      .col-6.mt-2
        label.form-label Erledigt von
        input.form-control(type='text' v-model="callback.DoneBy" disabled)
      .col-6.mt-2
        label.form-label Erstellt am
        input.form-control(type='text' :value="callback.CreatedAt | formatDateTime" disabled)
      .col-6.mt-2
        label.form-label Erstellt von
        input.form-control(type='text' v-model="callback.CreatedBy" disabled)
      .col-12.mt-3
        .float-right
          button.button.button-primary.button-tbook.mr-2(@click="setNotificationAsDone" v-if="callback.Id") erledigen
          button.button.button-primary.button-tdays(@click="saveCallback") speichern

</template>
<script>
import EventBus from "@/event-bus";
import { CallbacksComponent } from "@/lib/components/callbacks/CallbacksComponent.ts";
import { UsersComponent } from "@/lib/components/users/UsersComponent.ts";

export default {
  name: "CallbackModal",
  props: {
    callbackId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      callback: {},
    };
  },
  async mounted() {
    try {
      this.loadCallback();
    } catch (e) {
      console.error(e);
      this.$alert(e.message);
    }
  },
  methods: {
    async loadCallback() {
      try {
        const callbacksComponent = new CallbacksComponent();
        EventBus.$emit("changeLoadingState", true);
        const callback = await callbacksComponent.getCallback(
          this.$apollo,
          this.callbackId
        );
        EventBus.$emit("changeLoadingState", false);
        if (!callback) {
          return;
        }
        this.callback = callback;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async saveCallback() {
      try {
        EventBus.$emit("changeLoadingState", true);
        if (!this.callback.Id) {
          const user = await this.getUser();
          if (!user) {
            EventBus.$emit("changeLoadingState", false);
            return this.$alert("Es wurde kein Benutzer gefunden.");
          }
          this.callback.CreatedBy = `${user.FirstName} ${user.LastName}`;
        }
        const callbacksComponent = new CallbacksComponent();
        const savedCallback = await callbacksComponent.saveCallback(
          this.$apollo,
          this.callback
        );
        EventBus.$emit("changeLoadingState", false);
        if (!savedCallback) {
          return this.$alert("Der Rückruf konnte nicht gespeichert werden.");
        }
        if (savedCallback.Message) {
          if (savedCallback.IsSuccess) {
            this.$swal(savedCallback.Message);
          } else {
            this.$alert(savedCallback.Message);
          }
        }
        if (savedCallback.IsSuccess) {
          EventBus.$emit("updateCallbacks");
          this.$emit("close");
        }
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async setNotificationAsDone() {
      try {
        const confirmed = await this.$confirm(
          "Sind Sie sicher, dass Sie den Rückruf erledigen möchten?"
        )
          .then()
          .catch((e) => e);
        if (!confirmed) {
          return;
        }
        EventBus.$emit("changeLoadingState", true);
        const user = await this.getUser();
        if (!user) {
          EventBus.$emit("changeLoadingState", false);
          return this.$alert("Es wurde kein Benutzer gefunden.");
        }
        this.callback.DoneBy = `${user.FirstName} ${user.LastName}`;
        this.callback.DoneAt = new Date();

        const callbacksComponent = new CallbacksComponent();
        const savedCallback = await callbacksComponent.saveCallback(
          this.$apollo,
          this.callback
        );
        EventBus.$emit("changeLoadingState", false);
        if (!savedCallback) {
          return this.$alert("Der Rückruf konnte nicht gespeichert werden.");
        }
        if (savedCallback.Message) {
          if (savedCallback.IsSuccess) {
            this.$swal(savedCallback.Message);
          } else {
            this.$alert(savedCallback.Message);
          }
        }
        if (savedCallback.IsSuccess) {
          this.loadCallback();
        }
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async getUser() {
      try {
        const usersComponent = new UsersComponent();
        const user = await usersComponent.getUser(
          this.$apollo,
          this.$session.get("userId")
        );
        if (!user) {
          return undefined;
        }
        return user;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
};
</script>

<style scoped></style>
